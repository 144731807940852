<template>
  <component is="b-card">
    <b-overlay :show="busy" no-wrap fixed>
      <template v-slot:overlay>
        <div
          v-if="processing"
          class="text-center p-4 bg-primary text-light rounded"
        >
          <b-icon icon="cloud-upload" font-scale="4"></b-icon>
          <div class="mb-3">{{ $t("Processing") }}</div>
        </div>
      </template>
    </b-overlay>
    <b-card no-body class="mb-0">
      <b-row>
        <b-col>
          <b-form-group :label="$t('Date')">
            <b-form-input disabled v-model="date" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Total')">
            <b-form-input disabled v-model="totalRef" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        ref="refRecoveryListTable"
        class="position-relative"
        :items="fetchRecoverys"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
      >
        <!-- Column: Actions -->
        <template #cell(amount)="data"> {{ data.item.amount }} DH </template>
        <template #cell(actions)="data">
          <template v-if="data.item.etat">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="openEditAmountModal(data.item)"
            >
              <feather-icon icon="EditIcon" size="16" />
            </b-button>

            <b-modal
              :ref="'edit-recovery-' + data.item.id + '-amount'"
              ok-only
              ok-title="Edit"
              centered
              title="Edit"
              size="lg"
              no-close-on-backdrop
              @hidden="hiddenEditAmountModal"
              @ok="editAmount"
            >
              <b-row>
                <b-col>
                  <b-form-group>
                    <label for="ref_bon_caisse">Ref Bon Caisse</label>
                    <b-form-input
                      id="ref_bon_caisse"
                      type="text"
                      v-model="refBonCaisse"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group>
                    <label for="amount">Amount</label>
                    <b-form-input id="amount" type="number" v-model="amount" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    :label="$t('Select payment method')"
                    label-for="payment_method"
                  >
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="paymentMethods"
                      v-model="selectedPaymentMethod"
                      label="name"
                      input-id="payment_method"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <template v-if="selectedPaymentMethod">
                <b-row
                  v-if="
                    selectedPaymentMethod.name === 'Cheque' ||
                    selectedPaymentMethod.name === 'Effet'
                  "
                >
                  <b-col>
                    <b-form-group>
                      <label for="ref_cheque_effet">Ref Cheque/Effet</label>
                      <b-form-input
                        id="ref_cheque_effet"
                        type="text"
                        v-model="refChequeEffet"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('Date Echeance')" label-for="ref">
                      <flat-pickr
                        class="form-control"
                        :config="{ dateFormat: 'Y-m-d' }"
                        v-model="selectedDateEcheance"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </b-modal>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="validateRecovery(data.item)"
            >
              <feather-icon icon="CheckIcon" size="16" />
            </b-button>
          </template>
          <template v-else>
            <b-badge variant="primary">
              {{ $t("Received") }}
            </b-badge>
          </template>
        </template>
      </b-table>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BOverlay,
  BFormGroup,
  BIcon,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useValidatedRecoveryDetailList from "./useValidatedRecoveryDetailList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BIcon,
    BForm,
    BFormGroup,
    BOverlay,

    vSelect,
    flatPickr,
  },
  data() {
    return {
      date: this.$route.query.date,
      amount: null,
      refBonCaisse: null,
      selectedRecovory: null,
      selectedPaymentMethod: null,
      selectedDateEcheance: null,
      refChequeEffet: null,
      paymentMethods: [],
      busy: false,
      processing: false,
    };
  },
  async created() {
    try {
      const res = await instance.get("/parameters/payment-methods/");
      this.paymentMethods = res.data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    hiddenEditAmountModal() {
      this.selectedRecovory = null;
      this.amount = null;
    },
    openEditAmountModal(item) {
      this.selectedRecovory = item;
      this.amount = item.amount;
      this.refBonCaisse = item.ref_bon_caisse;
      this.refChequeEffet = item.ref_cheque_effet;
      this.selectedPaymentMethod = item.payment_method;
      this.selectedDateEcheance = item.date_echeance;

      this.$refs["edit-recovery-" + item.id + "-amount"].show();
    },
    async editAmount(bvEvent) {
      if (!this.refBonCaisse) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The ref bon caisse is required"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.amount) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The amount is required"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.selectedPaymentMethod) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The payment method is required"),
            icon: "InfoIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (
        this.selectedPaymentMethod.name === "Cheque" ||
        this.selectedPaymentMethod.name === "Effet"
      ) {
        if (!this.refChequeEffet) {
          bvEvent.preventDefault();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The Ref Cheque/Effet is required"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          return;
        }
        if (!this.selectedDateEcheance) {
          bvEvent.preventDefault();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The Date Echeance is required"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          return;
        }
      }

      try {
        await instance.put(`/recoveries/validate/`, {
          id: this.selectedRecovory.id,
          action: "edit",
          ref_bon_caisse: this.refBonCaisse,
          ref_cheque_effet: this.refChequeEffet,
          amount: this.amount,
          payment_method: this.selectedPaymentMethod.id,
          date_echeance: this.selectedDateEcheance,
        });

        this.refetchData();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async validateRecovery(item) {
      try {
        const result = await this.$bvModal.msgBoxConfirm(
          this.$t("Are you sure you want to validate this recovery?"),
          {
            title: "Alert",
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (!result) {
          return;
        }

        this.counter = 1;
        this.busy = this.processing = true;

        await instance.put(`/recoveries/validate/`, {
          id: item.id,
          action: "validate",
        });

        this.refetchData();
      } catch (err) {
        let message = err.message;

        if (err.response?.data) {
          message = err.response.data;
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.busy = this.processing = false;
      }
    },
  },

  setup() {
    const {
      fetchRecoverys,
      tableColumns,
      perPage,
      currentPage,
      totalRecoverys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecoveryListTable,
      refetchData,
      totalRef,
    } = useValidatedRecoveryDetailList();

    return {
      fetchRecoverys,
      tableColumns,
      perPage,
      currentPage,
      totalRecoverys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecoveryListTable,
      refetchData,
      // Filter
      avatarText,
      totalRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
